import React, { useEffect, memo } from 'react';
import dynamic from 'next/dynamic';
import Link from 'next/link';
import { useRouter } from 'next/router';

const LoginForm = dynamic(() => import('./Component/LoginForm'));

import { useLoading, useApp } from '@/components/App';

function Index() {
  const { state } = useApp();
  const router = useRouter();
  const { ButtonLoader } = useLoading();
  useEffect(() => {
    if (state?.user) {
      router.push('/');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state?.user]);
  return (
    <section className="login-wrap">
      {state?.user ? (
        <ButtonLoader color="#ff7350" />
      ) : (
        <div className="container">
          <div className="login-inner">
            <div className="row">
              <div className="col-md-6 login-img" style={{ backgroundImage: `url(/assets/img/login-bg.webp)` }}>
                <div className="login-cont">
                  <h3>NOT SIGNED IN YET?</h3>
                  <div className="login-info">
                    <p>Signing in you can</p>
                    <p>Manage your ads easily</p>
                    <p>Publish without waiting for the e-mail notification</p>
                  </div>
                  <Link href="/register" className="btn signup-btn clickable">
                    SIGN UP Here
                  </Link>
                </div>
              </div>
              <div className="col-md-6">
                <LoginForm />
              </div>
            </div>
          </div>
        </div>
      )}
      ;
    </section>
  );
}
export default memo(Index);
