import Head from 'next/head';

import Login from '@/Layout/Auth/Login';

function Index() {
  const homePage = {
    '@context': 'https://schema.org',
    '@type': 'WebPage',
    name: '247torax #1 Escort Classified Site | Call Girl in India | Login',
    description:
      'Meet with the high-profile call girls in your city. Find the call girl in Chennai, Bangalore, Hyderabad, Jaipur, Pune, Mumbai, Udaipur. POST YOUR AD absolutely FREE!',
    url: process.env.REACT_BASE_URL + '/login',
  };
  return (
    <>
      <Head>
        <title>{'247torax #1 Escort Classified Site | Call Girl in India | Login User'}</title>
        <meta name="title" content={'247torax #1 Escort Classified Site | Call Girl in India | Login User'} />
        <meta
          name="description"
          content={
            'Meet with the high-profile call girls in your city. Find the call girl in Chennai, Bangalore, Hyderabad, Jaipur, Pune, Mumbai, Udaipur. POST YOUR AD absolutely FREE!'
          }
        />
        <meta
          name="keyword"
          content={
            'Call girl Chennai, Call girl in Bangalore, Hyderabad call girl, Escort service Jaipur, Call girl Pune, Call girl in Mumbai, Udaipur call girl, Escort service Jaipur'
          }
        />
        <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: JSON.stringify(homePage) }}></script>
      </Head>
      <Login />
    </>
  );
}
Index.auth = false;
Index.meta = {
  siteUrl: process.env.REACT_BASE_URL + '/login',
  locale: 'en_us',
  ogTitle: 'Adult Classified site in India - 247torax',
  ogType: 'website',
  ogUrl: process.env.REACT_BASE_URL + '/login',
  siteName: '247torax',
  ogDescription:
    'Meet with the high-profile call girls in your city. Find the call girl in Chennai, Bangalore, Hyderabad, Jaipur, Pune, Mumbai, Udaipur. POST YOUR AD absolutely FREE!',
};
export default Index;
